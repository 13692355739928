/*!
=========================================================
* Argon Dashboard React - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Core

@import "custom/functions";
@import "custom/variables";
@import "custom/mixins";

// Bootstrap (4.1.3) components

@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/print";

// Argon utilities and components

@import "custom/reboot";
@import "custom/utilities";
@import "custom/components";

// Vendor (Plugins)

@import "custom/vendors";


// React differences
@import "react/react-differences";
@import url('https://fonts.googleapis.com/css?family=Nunito:300&display=swap');

.error-message{
    color: rgb(252, 94, 94);
    font-size: 12px;
    font-weight: 100;
    font-family: 'Nunito', sans-serif;
}

button:disabled{
    cursor: not-allowed;
    background: slategray;
}

 .input-group-alternative.mb-3.input-group:focus-within {
    
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
    border: 1px solid #007eff;
  }

  div.center-child{
      display: flex;
      align-items: center;
      justify-content: center;
  }
  

a.nav-link{
    cursor: pointer !important;
}
  .page-loading{
      margin-bottom: 300px;
  }

  .align-innertext th, .align-innertext td{
      text-align: left;
      padding: 10px 20px;
  }

  .align-innertext h3{
      text-align: left;
  }
  
th, td{
    padding: 10px 20px;


}

div.page-loader{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: slategray;
    font-size: 20px;
    margin: 40px 0 40px 0;
}

div > div.bg-gradient-info{
    background: linear-gradient(87deg, hsl(17, 91%, 65%) 0,hsl(17, 98%, 47%)  100% ) !important;
}

.fill-default{
    
    fill: aliceblue;
}

body.bg-default{
    background: aliceblue !important;
}

div > span.bg-gradient-default{
    background: linear-gradient(87deg, hsl(17, 91%, 65%) 0, hsl(17, 98%, 47%)  100%) !important;
    
}


.support{
   position: absolute;
   bottom: 2px;
   left: 0;
   font-size: 13px;
  
}

.support-card{
    padding-left: 20px;
    background: rgb(250, 251, 252);
    border: none;
    font-family: 'Helvetica';
    min-width: 220px;
    color: hsla(0, 0%, 60%, 0.9);
}
.page-info{
    display:flex;
    align-items: center;
    justify-content: center;
}





.page-not-found{
    position: absolute;
    height: 100%;
    width: 100%;
    background: #FFF;
}
.not-found-image{
    background: url('../img/brand/404manager.svg') no-repeat ;
    height: 70%;
    background-size: contain;
  background-position: top;
  background-color: aliceblue;
  }


  .not-found-text{
      background: aliceblue;
      height: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      
  }

  .notfound-logo{
    background: url('../img/brand/logo1.png') no-repeat;
    height: 2rem;
    background-size: contain;
    padding: 10px 10px 10px 20px;
  }

  .table td, .table th{
      padding: 10px;
  }


  
  .receipt-logo{
      height: 50px;
      width: 100px;
      display: none;
      margin: auto;
      margin-bottom: 10px;
  }

  .receipt-sponsor{
      display: none;
      margin-left: 20px;
      font-size: 1.3rem;
  }



  // styling for printing account generated for customers

  @media print {
    
    .section-to-print {
     color: black;
     position: absolute !important;
     font-size: 2rem;
    }
    .receipt-logo{
        display: block;
    }

   
  
    div.main-content{
      margin: 0 !important;
    }

    .receipt-footer, .footer, #sidenav-main{
        display:none;
    }
    

    .table td, .table th, tr > th, h3{
        padding: 20px;
        font-size: 1.5rem !important;
        
    }
    
    .receipt-sponsor{
        display: block;
    }
   
  
  }
  